<template>
  <div class="Footers">
    <div class="content">
      <div class="ul ul1">
        <img class="logo" src="@/assets/logo_white.png" alt="">
        <p>www.yzx91.com</p>
        <!-- <p>上海市徐汇区宜山路706-9号 枫林科创园B3北栋4楼</p> -->
        <p>咨询热线：13045656555</p>
      </div>
      <div class="ul ul2">
        <h3>快速入口</h3>
        <ul>
          <li @click="handleRouter('/home')">首页</li>
          <li @click="handleRouter('/service')">产品服务</li>
          <li @click="handleRouter('/news')">新闻资讯</li>
          <li @click="handleRouter('/about')">关于我们</li>
          <li @click="handleRouter('/joinus')">加入我们</li>
          <li @click="handleRouter()">帮助中心</li>
          <li @click="handleRouter('/about')">关于我们</li>
          <li @click="handleRouter('/login')">新用户注册</li>
        </ul>
      </div>
      <div class="ul ul3">
        <h3>快速入口</h3>
        <ul>
          <li><img src="@/assets/images/home/wb.png" alt="">推荐至微博</li>
          <li><img src="@/assets/images/home/qq.png" alt="">推荐至QQ空间</li>
          <!-- <li><img src="@/assets/images/home/fb.png" alt="">推荐至Facebook</li> -->
        </ul>
      </div>
      <div class="ul ul4">
        <h3>云智鑫（上海）信息科技技术有限公司</h3>
        <p>为中小企业提供信息化、电商化、互联网化的整体解决方案，真正做到让天下没有难做的企业！</p>
        <div class="box">
          <div class="box_li">
            <div class="img"><img src="@/assets/images/footer/xcx50.jpg" alt=""></div>
            <p>小程序</p>
          </div>
          <!-- <div class="box_li">
            <div class="img"></div>
            <p>关于我们</p>
          </div> -->
        </div>
      </div>
    </div>
    <div class="copyright"><a href="https://beian.miit.gov.cn/" target="_blank">沪备案号：沪ICP备2022030691号-1</a> <a href="https://www.beian.gov.cn/portal/registerSystemInfo" target="_blank"> 上海公安网备31010402009972号</a></div>
  </div>
</template>
<script>
export default {
  name:"Footers",
  data() {
    return {
      
    };
  },
  methods: {
    handleRouter(path){
      if(path){
        this.$router.push(path)
      }
      
    }
  }
}
</script>
<style lang="scss" scoped>
.Footers{
  width: 100%;
  height: 390px;
  background: #2E354D;
  .content{
    width: 1440px;
    height: calc( 100% - 60px );
    box-sizing: border-box;
    margin: auto;
    padding: 50px 80px 39px;
    border-bottom: 1px dashed rgba(255,255,255,0.10);
    color: #BBBBBB;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    background: #2E354D;
    .ul{
      padding-left: 40px;
      height: 100%;
      border-right: 1px solid rgba(255,255,255,0.10);
      h3{
        color: #fff;
        font-size: 18px;
        line-height: 25px;
      }
    }
    .ul1{
      width: 282px;
      .logo{
        width: 129px;
        height: 38px;
        display: block;
      }
      p:nth-of-type(1){
        line-height: 20px;
        margin-top: 16px;
      }
      p:nth-of-type(2){
        width: 193px;
        line-height: 20px;
        margin-top: 30px;
      }
    }
    .ul2{
      width: 343px;
      ul{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        li{
          width: 50%;
          line-height: 30px;
          margin-top: 14px;
          cursor: pointer;
        }
      }
    }
    .ul3{
      width: 247px;
      ul{
        width: 100%;
        li{
          display: flex;
          justify-content: flex-start;
          line-height: 30px;
          align-items: center;
          margin-top: 15px;
          img{
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }
        }
      }
    }
    .ul4{
      border: 0;
      p{
        width: 317px;
        margin-top: 24px;
      }
      .box{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .box_li{
          width: 90px;
          display: inline-block;
          margin-right: 40px;
          margin-top: 34px;
          .img{
            width: 90px;
            height: 90px;
            background: #D8D8D8;
            img{
              width: 100%;
              height: 100%;
            }
          }
          p{
            line-height: 20px;
            margin-top: 6px;
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
  .copyright{
    width: 1440px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: #2E354D;
    font-size: 14px;
    color: #BBBBBB;
    margin: auto;
    font-weight: 600;
    a{
      color: #BBBBBB;
    }
  }
}
</style>