import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

import Layout from '@/layout'

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/Home'),
        name: 'Home',
        meta: { title: '云智鑫'}
      },
      {
        path: 'service',
        component: () => import('@/views/Service'),
        name: 'Service',
        meta: { title: '云智鑫 产品与服务'}
      },
      {
        path: 'news',
        component: () => import('@/views/News'),
        name: 'News',
        meta: { title: '云智鑫 新闻资讯'}
      },
      {
        path: 'about',
        component: () => import('@/views/About'),
        name: 'About',
        meta: { title: '云智鑫 关于我们'}
      },
      {
        path: 'joinus',
        component: () => import('@/views/Joinus'),
        name: 'Joinus',
        meta: { title: '云智鑫 加入我们'}
      },
      {
        path: 'login',
        component: () => import('@/views/Login'),
        name: 'Login',
        meta: { title: '登录注册'}
      },
      {
        path: '/result',
        name: 'Result',
        component: () => import('@/views/common/result'),
        meta: { title: '提交结果'}
      },
      {
        path: '/new1',
        name: 'New',
        component: () => import('@/views/news/new1'),
        meta: { title: '云智鑫 新闻资讯'}
      },
    ]
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: () => import('@/views/common/agreement'),
    meta: { title: '协议'}
  },
  {
    path: '/result',
    name: 'Result',
    component: () => import('@/views/common/result'),
    meta: { title: '提交结果'}
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/common/404'),
    meta: { title: '404'}
  },
  { path: '*', redirect: '/404'}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  //配置滚动行为，跳转到新的路由界面滚动条的位置
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
