import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/common.scss'
import '@/assets/iconfont/iconfont.css'
import './permission' 

import { getDicts } from "@/api/dict"
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree, filterDataItem, filterAreaItem, filterCityData2, filterCityData1 } from "@/utils/ruoyi";
// 字典数据组件
import DictData from '@/components/DictData'
import plugins from './plugins' // plugins
Vue.use(plugins)
//自定义指令
import '@/utils/directive'
//
import ElementUI from 'element-ui';
import '@/assets/element-variables.scss'
Vue.use(ElementUI);


//aos.js动画
import "../node_modules/aos/dist/aos.css";
import AOS from "../node_modules/aos/dist/aos.js";
AOS.init({
  duration: 500,
});

// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.handleTree = handleTree
Vue.prototype.filterDataItem = filterDataItem
Vue.prototype.filterAreaItem = filterAreaItem
Vue.prototype.filterCityData2 = filterCityData2
Vue.prototype.filterCityData1 = filterCityData1

DictData.install()

console.log(process.env.VUE_APP_BASE_API)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
