import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import dict from './modules/dict'
import createPersistedState from "vuex-persistedstate"//
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage, 
      key:"client_vuex_data",//状态保存到本地的 key   
      paths:['dict'],//要持久化的状态，如果有嵌套，可以  a.b.c   
    })
  ],
  modules: {
    user,
    dict
  },
  getters,
})


export default store