import request from '@/utils/request'

//获取验证码
export function sendSmsCode(data) {
  return request({
    url: '/officialWebsite/sendSmsCode',
    method: 'get',
    params: data
  })
}

// 登录方法
export function login(phone, code, tenantId) {
  const data = {
    phone,
    code,
    tenantId
  }
  return request({
    url: '/officialWebsite/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/officialWebsite/get',
    method: 'get'
  })
}

// 获取省市区目录树列表
export function getAreaList() {
  return request({
    url: '/common/getAreaList',
    method: 'get'
  })
}

//暂存数据
export function updateTempCustomer(data) {
  return request({
    url: '/officialWebsite/updateTempCustomer',
    method: 'post',
    data: data
  })
}

//保存企业图片资料
export function updateFile(data){
  return request({
    url: '/officialWebsite/updateFile',
    method: 'post',
    data: data
  })
}

//删除企业客户图片资料
export function delCustFile(data){
  return request({
    url: '/officialWebsite/delCustFile',
    method: 'post',
    data: data
  })
}

// 获取图片验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    method: 'get',
    timeout: 20000
  })
}



//提交企业
export function saveEnterprise(data){
  return request({
    url: '/officialWebsite/saveEnterprise',
    method: 'post',
    data: data
  })
}

//联系我们
export function contact(data){
  return request({
    url: '/officialWebsite/contact',
    method: 'post',
    data: data
  })
}